import React from 'react'
import styled, { useTheme } from 'styled-components'
import Text from 'theme/Text'
import Icon from 'theme/Icon'
import Spacer from 'theme/Spacer'

const BuiltByWrapper = styled.a`
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 0 2rem;
  text-decoration: none;
`

const BuiltBy = props => {
  const theme = useTheme().frame.builtBy

  return (
    <BuiltByWrapper href="https://www.ellijaywebdesign.com" target="_blank">
      <Icon color={theme.logo} size="5rem" name="LIJ" />
      <Text>
        <Text color={theme.overtext}>Web Design by</Text>
        <Text color={theme.company} weight="bold">Ellijay Media Co</Text>
        <Spacer height="2px" />
        <Text color={theme.undertext} size="1rem">The North Georgia Web Design Studio</Text>
      </Text>
    </BuiltByWrapper>
  )
}

export default BuiltBy