import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import Text from 'theme/Text'
import MainMenu from 'frame/MainMenu'
import Spacer from 'theme/Spacer'
import { useInView } from 'react-intersection-observer'
import MainHeader from 'frame/MainHeader'
import { useHistory } from 'react-router-dom'
import themeColor from 'theme/themeColor'
import BG from 'theme/Background'
import BuiltBy from 'frame/BuiltBy'

const PageWrapper = styled.div`
  min-height: var(--app-height);
`

const PageContentWrapper = styled(BG).attrs({ background: 'frame.page.background' })`
  position: relative;
  margin: 10rem 2rem 2rem;
  border-radius: .5rem;
  box-shadow: 0 5px 5px ${themeColor('frame.page.shadow')};
  padding: 2rem;
  min-height: calc(var(--app-height) - 30rem);
  display: grid;
  grid-template-rows: min-content 1fr min-content;
`

const Page = props => {
  const { title, children } = props
  const [showMenu, setShowMenu] = useState(false)

  const { action } = useHistory()

  const handleMenu = useCallback(() => {
    setShowMenu(was => !was)
  }, [])

  const [ref, inView] = useInView({
    initialInView: true
  })

  useEffect(() => {
    if (action === 'PUSH') {
      // Going to a new page, scroll to top
      document.getElementById("main-wrapper").scrollTo(0,0)
    }
  }, [action])


  return (
    <PageWrapper id="page">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div ref={ref} />
      <MainHeader title={title} sticking={!inView} onMenu={handleMenu} />
      <PageContentWrapper>
        <div>{children}</div>
        <div />
        {showMenu && <MainMenu />}
      </PageContentWrapper>
      <BuiltBy />
      <Spacer height="3rem" />
    </PageWrapper>
  )
}

const NotFound = props => {
  return (
    <Page title={props.title || "No Found"}>
      <Text.H1>Not Found</Text.H1>
    </Page>
  )
}

Page.NotFound = NotFound

export default Page