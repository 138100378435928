import React from 'react'
import { ReactComponent as LIJ } from 'theme/svg/lij.svg'
import { ReactComponent as Menu } from 'theme/svg/menu.svg'
import styled from 'styled-components/macro'
import themeColor from 'theme/themeColor'

const icons = { LIJ, Menu }

const IconColorize = styled.div`
  height: ${props => props.size};
  width: ${props => props.width || 'auto'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  .icon-stroke {
    stroke: ${themeColor('black')};
    transition: stroke .3s;
  }
  .icon-fill {
    fill: ${themeColor('black')};
    transition: fill .3s;
  }
`

const Icon = props => {
  const { name, size, ...rest } = props
  const Component = icons[name]

  return (
    <IconColorize size={size || '3rem'} {...rest}>
      <Component />
    </IconColorize>
  )
}

export default Icon