import React from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Home from './pages/Home'
import Prices from './pages/Prices'
import Theme from 'theme'
import { HelmetProvider } from 'react-helmet-async'
import GlobalStyle from 'theme/GlobalStyle'
import BG from 'theme/Background'
import image from 'img/mountain-valley.png'
import tree from 'img/tree.png'
import { CustomProvider } from 'frame/Custom'

const Wrapper = styled(BG).attrs({
  background: 'frame.background'
})`
  overflow: scroll;
  height: 100%;
  min-height: var(--app-height);
`

function myTheme(theme) {
  const newTheme = { ...theme }
  newTheme.text.h1.family = 'Special Elite'
  newTheme.text.h2.family = 'Special Elite'
  // newTheme.text.h2.size = '2.1rem'
  newTheme.frame.background = {
    color: 'banner.orange',
    image: [{
      url: image,
      size: 'contain',
      repeat: 'no-repeat',
      attachment: 'fixed',
      position: 'center 50px',
    }, {
      url: tree,
      size: '200%',
      repeat: 'no-repeat',
      attachment: 'fixed',
      position: 'center 650px',
    }, {
      url: tree,
      size: '200%',
      repeat: 'no-repeat',
      attachment: 'fixed',
      position: 'center 400px',
    }, {
      url: tree,
      size: '200%',
      repeat: 'no-repeat',
      attachment: 'fixed',
      position: 'center 150px',
    }, {
      linearGradient: {
        options: 'to bottom',
        stops: [
          { color: 'banner.blue' },
          { color: 'banner.blue', position: '5rem' },
          { color: 'banner.orange', position: '5rem' },
        ]
      }
    }]
  }
  newTheme.frame.header = {
    scrolled: {
      background: 'banner.blue',
      shadow: 'black',
      title: {
        color: 'white',
      },
      logo: {
        color: 'white'
      },
      menu: {
        color: 'white'
      }
    },
    unscrolled: {
      background: 'clear',
      title: {
        color: 'white',
      },
      logo: {
        color: 'white'
      },
      menu: {
        color: 'white'
      }
    }
  }
  newTheme.frame.builtBy = {
    logo: 'white',
    overtext: 'white',
    company: 'white',
    undertext: 'white',
  }
  newTheme.components.loader.color = 'green'
  return newTheme
}

function App() {
  return (
    <HelmetProvider>
      <Theme theme={myTheme}>
        <GlobalStyle />
        <Router>
          <CustomProvider>
            <Wrapper id="main-wrapper">
            <Switch>
              <Route path="/prices">
                <Prices />
              </Route>
              <Route path="/">
                <Home />
              </Route>
              <Redirect to="/" />
            </Switch>
            </Wrapper>
          </CustomProvider>
        </Router>
      </Theme>
    </HelmetProvider>
  )
}

export default App
