import React, { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { useLocation } from 'react-router-dom'
import Text from 'theme/Text'
import Spacer from 'theme/Spacer'
import BG from 'theme/Background'

const Wrapper = styled(BG).attrs({ background: 'frame.menu.background' })`
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  padding: 2rem;
  z-index: 98;
  opacity: ${props => props.opacity};
  transition: opacity .3s;
  border-radius: .5rem;
`

const MenuLink = props => {
  const theme = useTheme()
  const location = useLocation()
  const { to, children } = props

  const sameLocation = location.pathname === to

  const { menu } = theme.frame

  const button = menu.button[sameLocation ? 'disabled' : 'enabled']

  return (
    <Text.Button.Link to={to} {...button}>
      {children}
    </Text.Button.Link>
  )
}

const Contents = styled.div`
  position: sticky;
  top: 8rem;
`

const MainMenu = props => {

  const [opacity, setOpacity] = useState(0.3)

  useEffect(() => {
    setTimeout(() => setOpacity(1))
  }, [])

  return (
    <Wrapper opacity={opacity}>
      <Contents>
        <MenuLink to="/">Home</MenuLink>
        <Spacer height="2rem" />
        <MenuLink to="/prices">Prices</MenuLink>
        <Spacer height="2rem" />
        <MenuLink to="/bar">Directions</MenuLink>
      </Contents>
    </Wrapper>
  )
}

export default MainMenu