import get from 'lodash/get'
import { transparentize } from 'polished'

const NO_OVERRIDE = 'NO_OVERRIDE'

const defaultOptions = { prop: 'color', defaultAlpha: 1 }

function getFromTheme(theme, prop, defaultAlpha) {
  let path, alpha = defaultAlpha
  if (prop && prop.indexOf('@') > 0) {
    [path, alpha] = prop.split('@')
  } else {
    path = prop
  }
  path = get(theme, path, path)
  if (path && path.indexOf('@') > 0) {
    [path, alpha] = path.split('@')
  }
  return [get(theme.colors, get(theme, path, path)), alpha]
}

const themeColor = (options=defaultOptions, defaultAlpha=1) => props => {
  if (typeof options === 'string') {
    options = { ...defaultOptions, defaultColor: options, defaultAlpha }
  }
  let { prop='color', defaultColor, defaultAlpha: da=defaultAlpha } = options
  const theme = props.theme
  let alpha = Number(props.alpha || da)
  let transparency, color
  if (props[prop] && props[prop] !== NO_OVERRIDE) {
    [color, alpha] = getFromTheme(theme, props[prop], alpha)
  }
  if (!color) {
    [color, alpha] = getFromTheme(theme, defaultColor, alpha)
  }
  if (!color) {
    [color, alpha] = getFromTheme(theme, 'inherit', alpha)
  }
  if (alpha < 1) {
    transparency = 1 - alpha
    return transparentize(transparency, `${color}`)
  } else {
    return color
  }
}

const use = (prop, defaultColor='colors.black', defaultAlpha=1) => themeColor({ prop, defaultColor, defaultAlpha })

const locked = (...args) => use(NO_OVERRIDE, ...args)

themeColor.use = use
themeColor.locked = locked

export default themeColor