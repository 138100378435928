import React from 'react'
import styled from 'styled-components'
import sold_out from 'img/sold_out.webp'
import BG from 'theme/Background'
import Text from 'theme/Text'
import groupBy from 'lodash/groupBy'
import themeColor from 'theme/themeColor'
import Spacer from 'theme/Spacer'

const tagOpts = {
  size: '3rem'
}

const Tag = styled(BG)`
  width: ${tagOpts.size};
  height: ${tagOpts.size};
`

const SoldOut = styled(Tag).attrs({ background: { image: {
  ...tagOpts, url: sold_out,
}}})``

const GetTag = props => {
  switch(props.tag) {
    case 'Sold Out': return <SoldOut />
    default: return null
  }
}

const Tags = styled.div`
  display: flex;
  align-items: flex-start;
  gap: .2rem;
`

const MenuItemWrapper = styled.div`
  margin-bottom: 2rem;
`

const NameAndPrice = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr minmax(min-content, max-content);
  grid-gap: .3rem;

  white-space: nowrap;
`

const Dots = styled.div`
  border-bottom: 2px dotted ${themeColor.locked('grey@.5')};
  margin-bottom: .3rem;
`

const TagsAndOptions = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const MenuItem = props => {
  const { name, description, price, upcharges=[], tags=[] } = props.item

  return (
    <MenuItemWrapper>
      <NameAndPrice>
        <Text color="green" weight="bold" size="1.6rem">{name}</Text>
        <Dots />
        <Text weight="bold" size="1.6rem">{price}</Text>
      </NameAndPrice>
      <Spacer height=".5rem" />
      {description ? <Text>{description}</Text> : null}
      <TagsAndOptions>
        {tags.length ? (
          <div>
            <Spacer height=".5rem" />
            <Tags>
              {tags.map(tag => <GetTag key={tag} tag={tag} />)}
            </Tags>
          </div>
        ) : <span />}
        {upcharges.length ? (
          <div>
            <Spacer height=".5rem" />
            <div>
              {upcharges.map(({name, price}) => (
                <NameAndPrice>
                  <Text color="grey.dark" weight="bold">{name}</Text>
                  <Dots />
                  <Text color="grey.dark" weight="bold">{price}</Text>
                </NameAndPrice>
              ))}
            </div>
          </div>
        ) : null}
      </TagsAndOptions>
    </MenuItemWrapper>
  )
}

const Section = props => {
  const { name, items } = props

  return (
    <div>
      <Text.H2>{name}</Text.H2>
      <div>
        {items.map(item => (
          <MenuItem item={item} />
        ))}
      </div>
    </div>
  )
}

const Menu = props => {
  const { menu } = props

  const grouped = groupBy(menu, 'section')

  return (
    <div>
      {Object.keys(grouped).map(section => (
        <Section name={section} items={grouped[section]} />
      ))}
    </div>
  )
}

export default Menu