import React from 'react'
import Page from 'frame/Page'
import Text from 'theme/Text'
import Spacer from 'theme/Spacer'
import BG from 'theme/Background'
import styled from 'styled-components'
import cows from 'img/cows.jpeg'
import thought from 'img/thought-bubble.png'
import themeColor from 'theme/themeColor'
import { CustomValue } from 'frame/Custom'

const Cows = styled(BG).attrs({ background: { image:cows } })`
  height: 19rem;
  position: relative;
`

const Thought = styled(BG).attrs({ background: { image: thought }})`
  height: 10rem;
  width: 10rem;
  position: absolute;
  right: 3rem;
  top: -3rem;
  display: grid;
  align-items: center;
  display: none;
`

const Heart = styled.span`
  color: ${themeColor.locked('clear')};
  text-shadow: 0 0 0 ${themeColor.locked('red')};
`

const Home = props => {
  return (
    <Page title="Mountain Valley Farm">
      <Text.H1>We Have Grass Fed Beef.</Text.H1>

      <Cows>
        <Thought>
          <Text align="center" margin="0 0 2rem" family="Special Elite">I <Heart>❤️</Heart>Grass</Text>
        </Thought>
      </Cows>

      
      <Text.P>
        Learn more about our cuts of <Text.Link to="/">grass fed beef.</Text.Link>
      </Text.P>

      <Text.H2><Text color="red">NEW!</Text> We now have Lamb too!</Text.H2>

      <Text.P margin="-2rem 0 2rem">
        <Text.Link to="/">Learn more</Text.Link>
      </Text.P>

      <CustomValue
        item="customize.pages.Home.items.News"
        render={value => console.log({ value }) || (
          <>
          <Text.H2><Text color="green">Lastest News!</Text></Text.H2>
          <Text.P margin="-2rem 0 2rem">{value}</Text.P>
          </>
        )}
      />

      <Spacer height="1rem" />

      <Text.Button.Link to="/prices">See Pricing</Text.Button.Link>

      <Spacer height="1rem" />

      <Text.Button>Directions</Text.Button>

      <Spacer height="3rem" />

      <Text.H2>Store Hours</Text.H2>

      <Text.H2 size="1.8rem" margin="0" color="green.dark">Summer Hours: </Text.H2>
      <Text color="grey.dark">March thru October</Text>
      <Text color="grey.darkest">Every Day 10 to 6</Text>

      <Spacer height="2rem" />

      <Text.H2 size="1.8rem" margin="0" color="green.dark">Winter Hours: </Text.H2>
      <Text color="grey.dark">November thru February</Text>
      <Text color="grey.darkest">Every day 9 to 5</Text>

      <Spacer height="2rem" />

      <Text.H2 align="center">More Coming Soon!</Text.H2>
    </Page>
  )
}

export default Home