import React from 'react'
import styled, { keyframes } from 'styled-components'
import themeColor from 'theme/themeColor'

const defaultSize = '10rem'

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  border-radius: 50%;
  width: ${props => props.size || defaultSize};
  height: ${props => props.size || defaultSize};

  font-size: calc(${props => props.size || defaultSize} / 10);
  position: relative;
  border-top: 1.1rem solid ${themeColor('components.loader.color',.2)};
  border-right: 1.1rem solid ${themeColor('components.loader.color',.2)};
  border-bottom: 1.1rem solid ${themeColor('components.loader.color',.2)};
  border-left: 1.1rem solid ${themeColor('components.loader.color')};
  transform: translateZ(0);
  animation: ${animation} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: ${props => props.size || defaultSize};
    height: ${props => props.size || defaultSize};
  }
`

const FullPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 10vh;
  background: rgba(255,255,255,.75);
  background: ${themeColor('frame.page.background@.75')};
  display: grid;
  align-items: start;
  justify-content: center;
  border-radius: 1rem;
`

const Page = props => (
  <FullPage>
    <Loader {...props} />
  </FullPage>
)

Loader.Page = Page

export default Loader