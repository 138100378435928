import React from 'react'
import Page from 'frame/Page'
import { CustomValue } from 'frame/Custom'
import Menu from 'components/Menu'

const Prices = props => {
  return (
    <Page title="Prices">
      <CustomValue
        item="customize.pages.Prices.items.Price List"
        render={menu => <Menu menu={menu} />}
      />
    </Page>
  )
}

export default Prices