import React from 'react'
import styled, { css, useTheme } from 'styled-components'
import Icon from 'theme/Icon'
import themeColor from 'theme/themeColor'
import Text from 'theme/Text'
import BG from 'theme/Background'

const HeaderWrapper = styled(BG)`
  display: grid;
  grid-template-columns: 1fr 3rem;
  grid-gap: 1rem;
  margin: 0;
  padding: 1rem 2rem 1rem;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 99;
  transition: all .3s;

  ${props => props.sticking && css`
    box-shadow: 0 -2px 13px ${themeColor('frame.header.scrolled.shadow')};
  `}
`

const MainHeader = props => {
  const { title, sticking, onMenu } = props
  const theme = useTheme()
  const titleColor = theme.frame.header[sticking ? 'scrolled' : 'unscrolled'].title.color
  const menuColor = theme.frame.header[sticking ? 'scrolled' : 'unscrolled'].menu.color
  const bg = `frame.header.${sticking ? '' : 'un'}scrolled.background`
  return (
    <HeaderWrapper background={bg} sticking={sticking}>
      <Text size="1.4rem" spacing=".5rem" family="PT Sans" transform="uppercase" align="left" color={titleColor}>{title}</Text>
      <Icon size="3rem" color={menuColor} onClick={onMenu} name="Menu" />
    </HeaderWrapper>
  )
}

export default MainHeader